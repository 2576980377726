












































// Core
import { Component, Prop, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import PerfectScrollbar from 'perfect-scrollbar'

// Types
import { UsersActions } from '@/store/users/types'

// Interfaces
import { Meta, Paginate, RequestParams } from '@/store/interfaces'
import { LegalPerson, NaturalPerson, UsersState } from '@/store/users/interfaces'

// Modules
const NSUsers = namespace('usersModule')

@Component({
  name: 'Card',

  components: {
    'v-dialog-legal': () => import('@/components/dialogs/DialogLegal.vue'),
    'v-dialog-choose-person': () => import('@/components/dialogs/DialogChoosePerson.vue'),
    'v-dialog-add-individual': () => import('@/components/dialogs/DialogAddIndividual.vue'),
  },
})
export default class CardComponent extends Vue {
  @Prop(Number) private projectId!: number

  @Prop({
    type: Object,
    default: () => ({}),
  })
  private legalPerson!: LegalPerson

  @Prop({
    type: Function,
    default: () => ({}),
  })
  private handleVisibleDialogLegal!: () => void

  @NSUsers.Action(UsersActions.A_FETCH_FILTERED_NATURAL_PERSONS)
  private fetchNaturalPersons!: (params?: RequestParams) => Promise<Paginate<NaturalPerson>>

  @NSUsers.Action(UsersActions.A_CONNECT_NATURAL_PERSON)
  private connectNaturalPerson!: (naturalPerson: NaturalPerson) => Promise<NaturalPerson>

  @NSUsers.Action(UsersActions.A_DISCONNECT_NATURAL_PERSON)
  private disconnectNaturalPerson!: (naturalPerson: NaturalPerson) => Promise<NaturalPerson>

  private visibleDialogLegal: boolean = false
  private visibleDialogChoosePerson: boolean = false
  private visibleDialogAddIndividual: boolean = false

  private ps: any = null

  private naturalPersons: NaturalPerson[] = []
  private meta: Meta | null = null

  private created() {
    this.handleFetchNaturalPersons()
  }

  private mounted() {
    new PerfectScrollbar(this.$refs['natural-persons'] as Element)
  }

  private handleFetchNaturalPersons() {
    this.fetchNaturalPersons({ projectId: this.projectId, legalPersonId: this.legalPerson.id }).then(naturalPersons => {
      this.naturalPersons = naturalPersons.data
      this.meta = naturalPersons.meta
    })
  }

  private handleConnectNaturalPerson(naturalPerson: NaturalPerson) {
    this.connectNaturalPerson({ ...naturalPerson, legalPersonId: this.legalPerson.id })
      .then(data => {
        this.$notify({
          title: 'Выполнено',
          message: 'Действие успешно выполнено',
          type: 'success',
        })

        this.handleFetchNaturalPersons()
      })
      .catch(error => {
        this.$notify.error({
          title: 'Ошибка',
          message: error.response.data.message,
        })
      })
  }

  private handleDisconnectNaturalPerson(naturalPerson: NaturalPerson) {
    this.disconnectNaturalPerson(naturalPerson)
      .then(data => {
        this.$notify({
          title: 'Выполнено',
          message: 'Действие успешно выполнено',
          type: 'success',
        })

        this.handleFetchNaturalPersons()
      })
      .catch(error => {
        this.$notify.error({
          title: 'Ошибка',
          message: error.response.data.message,
        })
      })
  }
}
